import { __awaiter, __extends, __generator } from "tslib";
import { ClientCacheState, ConfigServiceBase } from "./ConfigServiceBase";
var ManualPollConfigService = /** @class */ (function (_super) {
    __extends(ManualPollConfigService, _super);
    function ManualPollConfigService(configFetcher, options) {
        var _this = _super.call(this, configFetcher, options) || this;
        var initialCacheSyncUp = _this.syncUpWithCache();
        _this.readyPromise = _this.getReadyPromise(initialCacheSyncUp, function (initialCacheSyncUp) { return __awaiter(_this, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = this.getCacheState;
                    return [4 /*yield*/, initialCacheSyncUp];
                case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
            }
        }); }); });
        return _this;
    }
    ManualPollConfigService.prototype.getCacheState = function (cachedConfig) {
        if (cachedConfig.isEmpty) {
            return ClientCacheState.NoFlagData;
        }
        return ClientCacheState.HasCachedFlagDataOnly;
    };
    ManualPollConfigService.prototype.getConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("ManualPollService.getConfig() called.");
                        return [4 /*yield*/, this.options.cache.get(this.cacheKey)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ManualPollConfigService.prototype.refreshConfigAsync = function () {
        this.options.logger.debug("ManualPollService.refreshConfigAsync() called.");
        return _super.prototype.refreshConfigAsync.call(this);
    };
    return ManualPollConfigService;
}(ConfigServiceBase));
export { ManualPollConfigService };
