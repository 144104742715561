export function setupPolyfills() {
    // Object.values
    if (typeof Object.values === "undefined") {
        Object.values = ObjectValuesPolyfill;
    }
    // Object.entries
    if (typeof Object.entries === "undefined") {
        Object.entries = ObjectEntriesPolyfill;
    }
    // Object.fromEntries
    if (typeof Object.fromEntries === "undefined") {
        Object.fromEntries = ObjectFromEntriesPolyfill;
    }
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function ObjectValuesPolyfill(o) {
    var result = [];
    for (var _i = 0, _a = Object.keys(o); _i < _a.length; _i++) {
        var key = _a[_i];
        result.push(o[key]);
    }
    return result;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function ObjectEntriesPolyfill(o) {
    var result = [];
    for (var _i = 0, _a = Object.keys(o); _i < _a.length; _i++) {
        var key = _a[_i];
        result.push([key, o[key]]);
    }
    return result;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function ObjectFromEntriesPolyfill(entries) {
    var _a;
    // TODO: change `k: string` to `k: PropertyKey` in the following line and remove `as string` below
    // when updating to TypeScript 4.4 or newer (https://stackoverflow.com/a/64943542/8656352)
    var result = {};
    if (Array.isArray(entries)) {
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _b = entries_1[_i], key = _b[0], value = _b[1];
            result[key] = value;
        }
    }
    else if (typeof Symbol !== "undefined" && (entries === null || entries === void 0 ? void 0 : entries[Symbol.iterator])) {
        var iterator = entries[Symbol.iterator]();
        var element = void 0, done = void 0;
        while ((_a = iterator.next(), element = _a.value, done = _a.done, _a, !done)) {
            var key = element[0], value = element[1];
            result[key] = value;
        }
    }
    else {
        throw new TypeError("Object.fromEntries() requires a single iterable argument");
    }
    return result;
}
export function getWeakRefStub() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    var WeakRef = function (target) {
        this.target = target;
    };
    WeakRef.prototype.deref = function () {
        return this.target;
    };
    WeakRef.isFallback = true;
    return WeakRef;
}
export var isWeakRefAvailable = function () { return typeof WeakRef === "function"; };
