/**
 * User Object. Contains user attributes which are used for evaluating targeting rules and percentage options.
 * @remarks
 * Please note that the `User` class is not designed to be used as a DTO (data transfer object).
 * (Since the type of the `custom` property is polymorphic, it's not guaranteed that deserializing a serialized instance produces an instance with an identical or even valid data content.)
 **/
var User = /** @class */ (function () {
    function User(
    /** The unique identifier of the user or session (e.g. email address, primary key, session ID, etc.) */
    identifier, 
    /** Email address of the user. */
    email, 
    /** Country of the user. */
    country, 
    /**
     * Custom attributes of the user for advanced targeting rule definitions (e.g. user role, subscription type, etc.)
     * @remarks
     * All comparators support `string` values as User Object attribute (in some cases they need to be provided in a specific format though, see below),
     * but some of them also support other types of values. It depends on the comparator how the values will be handled. The following rules apply:
     *
     * **Text-based comparators** (EQUALS, IS ONE OF, etc.)
     * * accept `string` values,
     * * all other values are automatically converted to `string` (a warning will be logged but evaluation will continue as normal).
     *
     * **SemVer-based comparators** (IS ONE OF, &lt;, &gt;=, etc.)
     * * accept `string` values containing a properly formatted, valid semver value,
     * * all other values are considered invalid (a warning will be logged and the currently evaluated targeting rule will be skipped).
     *
     * **Number-based comparators** (=, &lt;, &gt;=, etc.)
     * * accept `number` values,
     * * accept `string` values containing a properly formatted, valid `number` value,
     * * all other values are considered invalid (a warning will be logged and the currently evaluated targeting rule will be skipped).
     *
     * **Date time-based comparators** (BEFORE / AFTER)
     * * accept `Date` values, which are automatically converted to a second-based Unix timestamp,
     * * accept `number` values representing a second-based Unix timestamp,
     * * accept `string` values containing a properly formatted, valid `number` value,
     * * all other values are considered invalid (a warning will be logged and the currently evaluated targeting rule will be skipped).
     *
     * **String array-based comparators** (ARRAY CONTAINS ANY OF / ARRAY NOT CONTAINS ANY OF)
     * * accept arrays of `string`,
     * * accept `string` values containing a valid JSON string which can be deserialized to an array of `string`,
     * * all other values are considered invalid (a warning will be logged and the currently evaluated targeting rule will be skipped).
     **/
    custom) {
        if (custom === void 0) { custom = {}; }
        this.identifier = identifier;
        this.email = email;
        this.country = country;
        this.custom = custom;
    }
    return User;
}());
export { User };
// NOTE: These functions could be instance methods of the User class, however formerly we suggested `const user = { ... }`-style initialization in the SDK docs,
// which would lead to "...is not a function" errors if we called functions on instances created that way as those don't have the correct prototype.
export function getUserAttribute(user, name) {
    var _a, _b;
    switch (name) {
        case "Identifier": return (_a = user.identifier) !== null && _a !== void 0 ? _a : "";
        case "Email": return user.email;
        case "Country": return user.country;
        default: return (_b = user.custom) === null || _b === void 0 ? void 0 : _b[name];
    }
}
export function getUserAttributes(user) {
    var _a;
    var result = {};
    var identifierAttribute = "Identifier";
    var emailAttribute = "Email";
    var countryAttribute = "Country";
    result[identifierAttribute] = (_a = user.identifier) !== null && _a !== void 0 ? _a : "";
    if (user.email != null) {
        result[emailAttribute] = user.email;
    }
    if (user.country != null) {
        result[countryAttribute] = user.country;
    }
    if (user.custom != null) {
        var wellKnownAttributes = [identifierAttribute, emailAttribute, countryAttribute];
        for (var _i = 0, _b = Object.entries(user.custom); _i < _b.length; _i++) {
            var _c = _b[_i], attributeName = _c[0], attributeValue = _c[1];
            if (attributeValue != null && wellKnownAttributes.indexOf(attributeName) < 0) {
                result[attributeName] = attributeValue;
            }
        }
    }
    return result;
}
