import { __extends } from "tslib";
var ProjectConfig = /** @class */ (function () {
    function ProjectConfig(configJson, config, timestamp, httpETag) {
        this.configJson = configJson;
        this.config = config;
        this.timestamp = timestamp;
        this.httpETag = httpETag;
    }
    ProjectConfig.equals = function (projectConfig1, projectConfig2) {
        // When both ETags are available, we don't need to check the JSON content.
        return projectConfig1.httpETag && projectConfig2.httpETag
            ? projectConfig1.httpETag === projectConfig2.httpETag
            : projectConfig1.configJson === projectConfig2.configJson;
    };
    ProjectConfig.prototype.with = function (timestamp) { return new ProjectConfig(this.configJson, this.config, timestamp, this.httpETag); };
    Object.defineProperty(ProjectConfig.prototype, "isEmpty", {
        get: function () { return !this.config; },
        enumerable: false,
        configurable: true
    });
    ProjectConfig.prototype.isExpired = function (expirationMs) {
        return this === ProjectConfig.empty || this.timestamp + expirationMs < ProjectConfig.generateTimestamp();
    };
    ProjectConfig.generateTimestamp = function () {
        return new Date().getTime();
    };
    ProjectConfig.serialize = function (config) {
        var _a, _b;
        return config.timestamp + "\n"
            + ((_a = config.httpETag) !== null && _a !== void 0 ? _a : "") + "\n"
            + ((_b = config.configJson) !== null && _b !== void 0 ? _b : "");
    };
    ProjectConfig.deserialize = function (value) {
        var separatorIndices = Array(2);
        var index = 0;
        for (var i = 0; i < separatorIndices.length; i++) {
            index = value.indexOf("\n", index);
            if (index < 0) {
                throw new Error("Number of values is fewer than expected.");
            }
            separatorIndices[i] = index++;
        }
        var endIndex = separatorIndices[0];
        var slice = value.substring(0, endIndex);
        var fetchTime = parseInt(slice);
        if (isNaN(fetchTime)) {
            throw new Error("Invalid fetch time: " + slice);
        }
        index = endIndex + 1;
        endIndex = separatorIndices[1];
        slice = value.substring(index, endIndex);
        var httpETag = slice.length > 0 ? slice : void 0;
        index = endIndex + 1;
        slice = value.substring(index);
        var config;
        var configJson;
        if (slice.length > 0) {
            config = Config.deserialize(slice);
            configJson = slice;
        }
        return new ProjectConfig(configJson, config, fetchTime, httpETag);
    };
    ProjectConfig.serializationFormatVersion = "v2";
    ProjectConfig.empty = new ProjectConfig(void 0, void 0, 0, void 0);
    return ProjectConfig;
}());
export { ProjectConfig };
var Config = /** @class */ (function () {
    function Config(json) {
        var _this = this;
        var _a, _b;
        this.preferences = json.p != null ? new Preferences(json.p) : void 0;
        this.segments = (_b = (_a = json.s) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new Segment(item); })) !== null && _b !== void 0 ? _b : [];
        this.settings = json.f != null
            ? Object.fromEntries(Object.entries(json.f).map(function (_a) {
                var key = _a[0], value = _a[1];
                return [key, new Setting(value, _this)];
            }))
            : {};
    }
    Config.deserialize = function (configJson) {
        var configJsonParsed = JSON.parse(configJson);
        if (typeof configJsonParsed !== "object" || !configJsonParsed) {
            throw new Error("Invalid config JSON content:" + configJson);
        }
        return new Config(configJsonParsed);
    };
    Object.defineProperty(Config.prototype, "salt", {
        get: function () { var _a; return (_a = this.preferences) === null || _a === void 0 ? void 0 : _a.salt; },
        enumerable: false,
        configurable: true
    });
    return Config;
}());
export { Config };
var Preferences = /** @class */ (function () {
    function Preferences(json) {
        this.baseUrl = json.u;
        this.redirectMode = json.r;
        this.salt = json.s;
    }
    return Preferences;
}());
export { Preferences };
var Segment = /** @class */ (function () {
    function Segment(json) {
        var _a, _b;
        this.name = json.n;
        this.conditions = (_b = (_a = json.r) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new UserCondition(item); })) !== null && _b !== void 0 ? _b : [];
    }
    return Segment;
}());
export { Segment };
var SettingValueContainer = /** @class */ (function () {
    function SettingValueContainer(json, hasUnwrappedValue) {
        if (hasUnwrappedValue === void 0) { hasUnwrappedValue = false; }
        this.value = (!hasUnwrappedValue ? unwrapSettingValue(json.v) : json.v);
        this.variationId = json.i;
    }
    return SettingValueContainer;
}());
export { SettingValueContainer };
var Setting = /** @class */ (function (_super) {
    __extends(Setting, _super);
    function Setting(json, config) {
        var _a, _b, _c, _d, _e, _f;
        var _this = _super.call(this, json, json.t < 0) || this;
        _this.type = json.t;
        var identifierAttribute = "Identifier";
        _this.percentageOptionsAttribute = (_a = json.a) !== null && _a !== void 0 ? _a : identifierAttribute;
        _this.targetingRules = (_c = (_b = json.r) === null || _b === void 0 ? void 0 : _b.map(function (item) { return new TargetingRule(item, config); })) !== null && _c !== void 0 ? _c : [];
        _this.percentageOptions = (_e = (_d = json.p) === null || _d === void 0 ? void 0 : _d.map(function (item) { return new PercentageOption(item); })) !== null && _e !== void 0 ? _e : [];
        _this.configJsonSalt = (_f = config === null || config === void 0 ? void 0 : config.salt) !== null && _f !== void 0 ? _f : "";
        return _this;
    }
    Setting.fromValue = function (value) {
        return new Setting({
            t: -1,
            v: value,
        });
    };
    return Setting;
}(SettingValueContainer));
export { Setting };
var TargetingRule = /** @class */ (function () {
    function TargetingRule(json, config) {
        var _a, _b;
        this.conditions = (_b = (_a = json.c) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            return item.u != null ? new UserCondition(item.u) :
                item.p != null ? new PrerequisiteFlagCondition(item.p) :
                    item.s != null ? new SegmentCondition(item.s, config) :
                        void 0;
        })) !== null && _b !== void 0 ? _b : [];
        this.then = json.p != null
            ? json.p.map(function (item) { return new PercentageOption(item); })
            : new SettingValueContainer(json.s);
    }
    return TargetingRule;
}());
export { TargetingRule };
var PercentageOption = /** @class */ (function (_super) {
    __extends(PercentageOption, _super);
    function PercentageOption(json) {
        var _this = _super.call(this, json) || this;
        _this.percentage = json.p;
        return _this;
    }
    return PercentageOption;
}(SettingValueContainer));
export { PercentageOption };
var UserCondition = /** @class */ (function () {
    function UserCondition(json) {
        var _a, _b;
        this.type = "UserCondition";
        this.comparisonAttribute = json.a;
        this.comparator = json.c;
        this.comparisonValue = ((_b = (_a = json.s) !== null && _a !== void 0 ? _a : json.d) !== null && _b !== void 0 ? _b : json.l);
    }
    return UserCondition;
}());
export { UserCondition };
var PrerequisiteFlagCondition = /** @class */ (function () {
    function PrerequisiteFlagCondition(json) {
        this.type = "PrerequisiteFlagCondition";
        this.prerequisiteFlagKey = json.f;
        this.comparator = json.c;
        this.comparisonValue = unwrapSettingValue(json.v);
    }
    return PrerequisiteFlagCondition;
}());
export { PrerequisiteFlagCondition };
var SegmentCondition = /** @class */ (function () {
    function SegmentCondition(json, config) {
        this.type = "SegmentCondition";
        this.segment = config.segments[json.s];
        this.comparator = json.c;
    }
    return SegmentCondition;
}());
export { SegmentCondition };
function unwrapSettingValue(json) {
    var _a, _b, _c;
    return ((_c = (_b = (_a = json.b) !== null && _a !== void 0 ? _a : json.s) !== null && _b !== void 0 ? _b : json.i) !== null && _c !== void 0 ? _c : json.d);
}
