/**
 * The ConfigCat config_v6.json schema that is used by the ConfigCat SDKs, described using TypeScript types.
 */
export var RedirectMode;
(function (RedirectMode) {
    RedirectMode[RedirectMode["No"] = 0] = "No";
    RedirectMode[RedirectMode["Should"] = 1] = "Should";
    RedirectMode[RedirectMode["Force"] = 2] = "Force";
})(RedirectMode || (RedirectMode = {}));
/** Setting type. */
export var SettingType;
(function (SettingType) {
    /** On/off type (feature flag). */
    SettingType[SettingType["Boolean"] = 0] = "Boolean";
    /** Text type. */
    SettingType[SettingType["String"] = 1] = "String";
    /** Whole number type. */
    SettingType[SettingType["Int"] = 2] = "Int";
    /** Decimal number type. */
    SettingType[SettingType["Double"] = 3] = "Double";
})(SettingType || (SettingType = {}));
/** User Object attribute comparison operator used during the evaluation process. */
export var UserComparator;
(function (UserComparator) {
    /** IS ONE OF (cleartext) - Checks whether the comparison attribute is equal to any of the comparison values. */
    UserComparator[UserComparator["TextIsOneOf"] = 0] = "TextIsOneOf";
    /** IS NOT ONE OF (cleartext) - Checks whether the comparison attribute is not equal to any of the comparison values. */
    UserComparator[UserComparator["TextIsNotOneOf"] = 1] = "TextIsNotOneOf";
    /** CONTAINS ANY OF (cleartext) - Checks whether the comparison attribute contains any comparison values as a substring. */
    UserComparator[UserComparator["TextContainsAnyOf"] = 2] = "TextContainsAnyOf";
    /** NOT CONTAINS ANY OF (cleartext) - Checks whether the comparison attribute does not contain any comparison values as a substring. */
    UserComparator[UserComparator["TextNotContainsAnyOf"] = 3] = "TextNotContainsAnyOf";
    /** IS ONE OF (semver) - Checks whether the comparison attribute interpreted as a semantic version is equal to any of the comparison values. */
    UserComparator[UserComparator["SemVerIsOneOf"] = 4] = "SemVerIsOneOf";
    /** IS NOT ONE OF (semver) - Checks whether the comparison attribute interpreted as a semantic version is not equal to any of the comparison values. */
    UserComparator[UserComparator["SemVerIsNotOneOf"] = 5] = "SemVerIsNotOneOf";
    /** &lt; (semver) - Checks whether the comparison attribute interpreted as a semantic version is less than the comparison value. */
    UserComparator[UserComparator["SemVerLess"] = 6] = "SemVerLess";
    /** &lt;= (semver) - Checks whether the comparison attribute interpreted as a semantic version is less than or equal to the comparison value. */
    UserComparator[UserComparator["SemVerLessOrEquals"] = 7] = "SemVerLessOrEquals";
    /** &gt; (semver) - Checks whether the comparison attribute interpreted as a semantic version is greater than the comparison value. */
    UserComparator[UserComparator["SemVerGreater"] = 8] = "SemVerGreater";
    /** &gt;= (semver) - Checks whether the comparison attribute interpreted as a semantic version is greater than or equal to the comparison value. */
    UserComparator[UserComparator["SemVerGreaterOrEquals"] = 9] = "SemVerGreaterOrEquals";
    /** = (number) - Checks whether the comparison attribute interpreted as a decimal number is equal to the comparison value. */
    UserComparator[UserComparator["NumberEquals"] = 10] = "NumberEquals";
    /** != (number) - Checks whether the comparison attribute interpreted as a decimal number is not equal to the comparison value. */
    UserComparator[UserComparator["NumberNotEquals"] = 11] = "NumberNotEquals";
    /** &lt; (number) - Checks whether the comparison attribute interpreted as a decimal number is less than the comparison value. */
    UserComparator[UserComparator["NumberLess"] = 12] = "NumberLess";
    /** &lt;= (number) - Checks whether the comparison attribute interpreted as a decimal number is less than or equal to the comparison value. */
    UserComparator[UserComparator["NumberLessOrEquals"] = 13] = "NumberLessOrEquals";
    /** &gt; (number) - Checks whether the comparison attribute interpreted as a decimal number is greater than the comparison value. */
    UserComparator[UserComparator["NumberGreater"] = 14] = "NumberGreater";
    /** &gt;= (number) - Checks whether the comparison attribute interpreted as a decimal number is greater than or equal to the comparison value. */
    UserComparator[UserComparator["NumberGreaterOrEquals"] = 15] = "NumberGreaterOrEquals";
    /** IS ONE OF (hashed) - Checks whether the comparison attribute is equal to any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextIsOneOf"] = 16] = "SensitiveTextIsOneOf";
    /** IS NOT ONE OF (hashed) - Checks whether the comparison attribute is not equal to any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextIsNotOneOf"] = 17] = "SensitiveTextIsNotOneOf";
    /** BEFORE (UTC datetime) - Checks whether the comparison attribute interpreted as the seconds elapsed since <see href="https://en.wikipedia.org/wiki/Unix_time">Unix Epoch</see> is less than the comparison value. */
    UserComparator[UserComparator["DateTimeBefore"] = 18] = "DateTimeBefore";
    /** AFTER (UTC datetime) - Checks whether the comparison attribute interpreted as the seconds elapsed since <see href="https://en.wikipedia.org/wiki/Unix_time">Unix Epoch</see> is greater than the comparison value. */
    UserComparator[UserComparator["DateTimeAfter"] = 19] = "DateTimeAfter";
    /** EQUALS (hashed) - Checks whether the comparison attribute is equal to the comparison value (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextEquals"] = 20] = "SensitiveTextEquals";
    /** NOT EQUALS (hashed) - Checks whether the comparison attribute is not equal to the comparison value (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextNotEquals"] = 21] = "SensitiveTextNotEquals";
    /** STARTS WITH ANY OF (hashed) - Checks whether the comparison attribute starts with any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextStartsWithAnyOf"] = 22] = "SensitiveTextStartsWithAnyOf";
    /** NOT STARTS WITH ANY OF (hashed) - Checks whether the comparison attribute does not start with any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextNotStartsWithAnyOf"] = 23] = "SensitiveTextNotStartsWithAnyOf";
    /** ENDS WITH ANY OF (hashed) - Checks whether the comparison attribute ends with any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextEndsWithAnyOf"] = 24] = "SensitiveTextEndsWithAnyOf";
    /** NOT ENDS WITH ANY OF (hashed) - Checks whether the comparison attribute does not end with any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveTextNotEndsWithAnyOf"] = 25] = "SensitiveTextNotEndsWithAnyOf";
    /** ARRAY CONTAINS ANY OF (hashed) - Checks whether the comparison attribute interpreted as a comma-separated list contains any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveArrayContainsAnyOf"] = 26] = "SensitiveArrayContainsAnyOf";
    /** ARRAY NOT CONTAINS ANY OF (hashed) - Checks whether the comparison attribute interpreted as a comma-separated list does not contain any of the comparison values (where the comparison is performed using the salted SHA256 hashes of the values). */
    UserComparator[UserComparator["SensitiveArrayNotContainsAnyOf"] = 27] = "SensitiveArrayNotContainsAnyOf";
    /** EQUALS (cleartext) - Checks whether the comparison attribute is equal to the comparison value. */
    UserComparator[UserComparator["TextEquals"] = 28] = "TextEquals";
    /** NOT EQUALS (cleartext) - Checks whether the comparison attribute is not equal to the comparison value. */
    UserComparator[UserComparator["TextNotEquals"] = 29] = "TextNotEquals";
    /** STARTS WITH ANY OF (cleartext) - Checks whether the comparison attribute starts with any of the comparison values. */
    UserComparator[UserComparator["TextStartsWithAnyOf"] = 30] = "TextStartsWithAnyOf";
    /** NOT STARTS WITH ANY OF (cleartext) - Checks whether the comparison attribute does not start with any of the comparison values. */
    UserComparator[UserComparator["TextNotStartsWithAnyOf"] = 31] = "TextNotStartsWithAnyOf";
    /** ENDS WITH ANY OF (cleartext) - Checks whether the comparison attribute ends with any of the comparison values. */
    UserComparator[UserComparator["TextEndsWithAnyOf"] = 32] = "TextEndsWithAnyOf";
    /** NOT ENDS WITH ANY OF (cleartext) - Checks whether the comparison attribute does not end with any of the comparison values. */
    UserComparator[UserComparator["TextNotEndsWithAnyOf"] = 33] = "TextNotEndsWithAnyOf";
    /** ARRAY CONTAINS ANY OF (cleartext) - Checks whether the comparison attribute interpreted as a comma-separated list contains any of the comparison values. */
    UserComparator[UserComparator["ArrayContainsAnyOf"] = 34] = "ArrayContainsAnyOf";
    /** ARRAY NOT CONTAINS ANY OF (cleartext) - Checks whether the comparison attribute interpreted as a comma-separated list does not contain any of the comparison values. */
    UserComparator[UserComparator["ArrayNotContainsAnyOf"] = 35] = "ArrayNotContainsAnyOf";
})(UserComparator || (UserComparator = {}));
/** Prerequisite flag comparison operator used during the evaluation process. */
export var PrerequisiteFlagComparator;
(function (PrerequisiteFlagComparator) {
    /** EQUALS - Checks whether the evaluated value of the specified prerequisite flag is equal to the comparison value. */
    PrerequisiteFlagComparator[PrerequisiteFlagComparator["Equals"] = 0] = "Equals";
    /** NOT EQUALS - Checks whether the evaluated value of the specified prerequisite flag is not equal to the comparison value. */
    PrerequisiteFlagComparator[PrerequisiteFlagComparator["NotEquals"] = 1] = "NotEquals";
})(PrerequisiteFlagComparator || (PrerequisiteFlagComparator = {}));
/** Segment comparison operator used during the evaluation process. */
export var SegmentComparator;
(function (SegmentComparator) {
    /** IS IN SEGMENT - Checks whether the conditions of the specified segment are evaluated to true. */
    SegmentComparator[SegmentComparator["IsIn"] = 0] = "IsIn";
    /** IS NOT IN SEGMENT - Checks whether the conditions of the specified segment are evaluated to false. */
    SegmentComparator[SegmentComparator["IsNotIn"] = 1] = "IsNotIn";
})(SegmentComparator || (SegmentComparator = {}));
