import { __awaiter, __extends, __generator } from "tslib";
import { ClientCacheState, ConfigServiceBase } from "./ConfigServiceBase";
import { delay } from "./Utils";
var AutoPollConfigService = /** @class */ (function (_super) {
    __extends(AutoPollConfigService, _super);
    function AutoPollConfigService(configFetcher, options) {
        var _this = _super.call(this, configFetcher, options) || this;
        _this.signalInitialization = function () { };
        _this.pollIntervalMs = options.pollIntervalSeconds * 1000;
        var initialCacheSyncUp = _this.syncUpWithCache();
        if (options.maxInitWaitTimeSeconds !== 0) {
            _this.initialized = false;
            // This promise will be resolved when
            // 1. the cache contains a valid config at startup (see startRefreshWorker) or
            // 2. config json is fetched the first time, regardless of success or failure (see onConfigUpdated).
            var initSignalPromise = new Promise(function (resolve) { return _this.signalInitialization = resolve; });
            // This promise will be resolved when either initialization ready is signalled by signalInitialization() or maxInitWaitTimeSeconds pass.
            _this.initializationPromise = _this.waitForInitializationAsync(initSignalPromise).then(function (success) {
                _this.initialized = true;
                return success;
            });
        }
        else {
            _this.initialized = true;
            _this.initializationPromise = Promise.resolve(false);
        }
        _this.readyPromise = _this.getReadyPromise(_this.initializationPromise, function (initializationPromise) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, initializationPromise];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.getCacheState(this.options.cache.getInMemory())];
                }
            });
        }); });
        if (!options.offline) {
            _this.startRefreshWorker(initialCacheSyncUp);
        }
        return _this;
    }
    AutoPollConfigService.prototype.waitForInitializationAsync = function (initSignalPromise) {
        return __awaiter(this, void 0, void 0, function () {
            var delayCleanup, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.options.maxInitWaitTimeSeconds < 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, initSignalPromise];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        delayCleanup = {};
                        return [4 /*yield*/, Promise.race([
                                initSignalPromise.then(function () { return true; }),
                                delay(this.options.maxInitWaitTimeSeconds * 1000, delayCleanup).then(function () { return false; })
                            ])];
                    case 3:
                        success = _a.sent();
                        delayCleanup.clearTimer();
                        return [2 /*return*/, success];
                }
            });
        });
    };
    AutoPollConfigService.prototype.getConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            function logSuccess(logger) {
                logger.debug("AutoPollConfigService.getConfig() - returning value from cache.");
            }
            var cachedConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("AutoPollConfigService.getConfig() called.");
                        if (!(!this.isOffline && !this.initialized)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.options.cache.get(this.cacheKey)];
                    case 1:
                        cachedConfig = _a.sent();
                        if (!cachedConfig.isExpired(this.pollIntervalMs)) {
                            logSuccess(this.options.logger);
                            return [2 /*return*/, cachedConfig];
                        }
                        this.options.logger.debug("AutoPollConfigService.getConfig() - cache is empty or expired, waiting for initialization.");
                        return [4 /*yield*/, this.initializationPromise];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.options.cache.get(this.cacheKey)];
                    case 4:
                        cachedConfig = _a.sent();
                        if (!cachedConfig.isExpired(this.pollIntervalMs)) {
                            logSuccess(this.options.logger);
                        }
                        else {
                            this.options.logger.debug("AutoPollConfigService.getConfig() - cache is empty or expired.");
                        }
                        return [2 /*return*/, cachedConfig];
                }
            });
        });
    };
    AutoPollConfigService.prototype.refreshConfigAsync = function () {
        this.options.logger.debug("AutoPollConfigService.refreshConfigAsync() called.");
        return _super.prototype.refreshConfigAsync.call(this);
    };
    AutoPollConfigService.prototype.dispose = function () {
        this.options.logger.debug("AutoPollConfigService.dispose() called.");
        _super.prototype.dispose.call(this);
        if (this.workerTimerId) {
            this.stopRefreshWorker();
        }
    };
    AutoPollConfigService.prototype.onConfigFetched = function (newConfig) {
        _super.prototype.onConfigFetched.call(this, newConfig);
        this.signalInitialization();
    };
    AutoPollConfigService.prototype.setOnlineCore = function () {
        this.startRefreshWorker();
    };
    AutoPollConfigService.prototype.setOfflineCore = function () {
        this.stopRefreshWorker();
    };
    AutoPollConfigService.prototype.startRefreshWorker = function (initialCacheSyncUp) {
        return __awaiter(this, void 0, void 0, function () {
            var delayMs, latestConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("AutoPollConfigService.startRefreshWorker() called.");
                        delayMs = this.pollIntervalMs;
                        return [4 /*yield*/, (initialCacheSyncUp !== null && initialCacheSyncUp !== void 0 ? initialCacheSyncUp : this.options.cache.get(this.cacheKey))];
                    case 1:
                        latestConfig = _a.sent();
                        if (!latestConfig.isExpired(this.pollIntervalMs)) return [3 /*break*/, 4];
                        if (!!this.isOfflineExactly) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.refreshConfigCoreAsync(latestConfig)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.signalInitialization();
                        _a.label = 5;
                    case 5:
                        this.options.logger.debug("AutoPollConfigService.startRefreshWorker() - calling refreshWorkerLogic()'s setTimeout.");
                        this.workerTimerId = setTimeout(function (d) { return _this.refreshWorkerLogic(d); }, delayMs, delayMs);
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoPollConfigService.prototype.stopRefreshWorker = function () {
        this.options.logger.debug("AutoPollConfigService.stopRefreshWorker() - clearing setTimeout.");
        clearTimeout(this.workerTimerId);
    };
    AutoPollConfigService.prototype.refreshWorkerLogic = function (delayMs) {
        return __awaiter(this, void 0, void 0, function () {
            var latestConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.disposed) {
                            this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - called on a disposed client.");
                            return [2 /*return*/];
                        }
                        this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - called.");
                        if (!!this.isOffline) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.options.cache.get(this.cacheKey)];
                    case 1:
                        latestConfig = _a.sent();
                        return [4 /*yield*/, this.refreshConfigCoreAsync(latestConfig)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - calling refreshWorkerLogic()'s setTimeout.");
                        this.workerTimerId = setTimeout(function (d) { return _this.refreshWorkerLogic(d); }, delayMs, delayMs);
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoPollConfigService.prototype.getCacheState = function (cachedConfig) {
        if (cachedConfig.isEmpty) {
            return ClientCacheState.NoFlagData;
        }
        if (cachedConfig.isExpired(this.pollIntervalMs)) {
            return ClientCacheState.HasCachedFlagDataOnly;
        }
        return ClientCacheState.HasUpToDateFlagData;
    };
    return AutoPollConfigService;
}(ConfigServiceBase));
export { AutoPollConfigService };
