import { PollingMode, getClient as getClientCommon } from "configcat-common";
import { LocalStorageCache } from "./Cache";
import { HttpConfigFetcher } from "./ConfigFetcher";
import CONFIGCAT_SDK_VERSION from "./Version";
/**
 * Returns an instance of `ConfigCatClient` for the specified SDK Key.
 * @remarks This method returns a single, shared instance per each distinct SDK Key.
 * That is, a new client object is created only when there is none available for the specified SDK Key.
 * Otherwise, the already created instance is returned (in which case the `pollingMode` and `options` arguments are ignored).
 * So, please keep in mind that when you make multiple calls to this method using the same SDK Key, you may end up with multiple references to the same client object.
 * @param sdkKey SDK Key to access the ConfigCat config.
 * @param pollingMode The polling mode to use.
 * @param options Options for the specified polling mode.
 */
export function getClient(sdkKey, pollingMode, options) {
    return getClientCommon(sdkKey, pollingMode !== null && pollingMode !== void 0 ? pollingMode : PollingMode.AutoPoll, options, LocalStorageCache.setup({
        configFetcher: new HttpConfigFetcher(),
        sdkType: "ConfigCat-JS",
        sdkVersion: CONFIGCAT_SDK_VERSION,
    }));
}
export { disposeAllClients, createConsoleLogger, createFlagOverridesFromMap } from "configcat-common";
/* Public types re-export from common-js */
// These exports should be kept in sync with the exports listed in the section "Public types for end users" of common-js/src/index.ts!
export { PollingMode } from "configcat-common";
export { DataGovernance } from "configcat-common";
export { LogLevel } from "configcat-common";
export { FormattableLogMessage } from "configcat-common";
export { SettingType, UserComparator, PrerequisiteFlagComparator, SegmentComparator } from "configcat-common";
export { SettingKeyValue } from "configcat-common";
export { User } from "configcat-common";
export { OverrideBehaviour } from "configcat-common";
export { ClientCacheState, RefreshResult } from "configcat-common";
/* Default export */
export default function (sdkKey, options) {
    return getClient(sdkKey, PollingMode.AutoPoll, options);
}
