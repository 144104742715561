import * as configCat from 'configcat-js';

const configCatClient = configCat.getClient(
  (window.ENV_DATA || {}).configCatKey || process.env.CONFIGCAT_KEY!,
  configCat.PollingMode.LazyLoad,
  { requestTimeoutMs: 1000 }
);

export const getFeature = async (
  key: string,
  defaultValue: boolean,
  custom: configCat.User['custom']
) => {
  const value = await configCatClient.getValueAsync(key, defaultValue, {
    identifier: window.GLAppInsights.context.user.id,
    custom
  });

  return value as boolean;
};
