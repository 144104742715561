/* eslint-disable no-console */

import qs from 'qs';

const getGoogleClientId = async (gaMeasurementId: string) =>
  new Promise(resolve => {
    window.gtag('get', gaMeasurementId, 'client_id', resolve);
  });
const getGoogleSessionId = async (gaMeasurementId: string) =>
  new Promise(resolve => {
    window.gtag('get', gaMeasurementId, 'session_id', resolve);
  });
const getGoogleAdsId = async (gaMeasurementId: string) =>
  new Promise(resolve => {
    window.gtag('get', gaMeasurementId, 'gclid', resolve);
  });

export default async function getGoogleAnalyticsLinkerQuery(): Promise<string | null> {
  //It has Analytics
  if (window.google_tag_manager || window.google_tag_data) {
    // https://support.google.com/analytics/answer/10271001#zippy=%2Cin-this-article
    const measurementId = Object.keys(window.google_tag_manager).find(i => i.startsWith('G-'));

    if (measurementId) {
      if (!window.dataLayer) window.dataLayer = [];

      // adding support in case gtag is not set by customer when g4
      // is added via gtm and they do not have ua anymore.
      if (!window.ga && !window.gtag) {
        window.gtag = function() {
          window.dataLayer.push(arguments);
        };
      }

      if (window.gtag && !window.ga) {
        try {
          const [clientId, sessionId, googleAdsId] = await Promise.all([
            getGoogleClientId(measurementId),
            getGoogleSessionId(measurementId),
            getGoogleAdsId(measurementId)
          ]);

          if (clientId && sessionId) {
            const googleParams: any = {
              measurementId,
              clientId,
              sessionId,
              ts: Date.now()
            };

            if (googleAdsId) googleParams['gclid'] = googleAdsId;
            return qs.stringify({ ga4: googleParams });
          }
        } catch (error) {
          // do nothing
        }
      }
    }
  }

  // TODO: delete this after 1st of July 2024
  //UA Universal Analytics Support
  if (window.ga) {
    try {
      const tracker = window.ga.getAll()[0];
      return `_ga=${tracker.get('linkerParam').match(/^_ga=(.+)/)[1]}`;
    } catch {
      // do nothing
    }
  }

  return null;
}
