import { EventEmitter } from 'events';
import * as request from './utils/request';
import triggers from './triggers';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IntegrationConfig, DBMConfig, BrandingConfig, AnalyticsConfig } from './interfaces';
import { getFeature } from './configCat';

declare global {
  interface ClientConfig {
    aiKey: string;
    dbmUrl: string;
    dbmNewUrl: string;
    cdnEditorDomain: string;
    configCatKey: string;
    editorDomain: string;
  }

  interface Window {
    oc: any;
    ENV_DATA: Readonly<ClientConfig>;
    guestlineEmitter: any;
    ga:any,
    gtag: any;
    google_tag_manager:any,
    google_tag_data: any;
    dataLayer:any;
    GLAppInsights: ApplicationInsights;
    dbm: any;
    glDBMTagReady: boolean;
  }
}

export type Language = 'en' | 'de' | 'nl' | 'pt' | 'es' | 'it';


const debugEnabled = window.location.search.includes('dbm-debug=');
const CDN_EDITOR_DOMAIN = debugEnabled
  ? (window.ENV_DATA || {}).editorDomain || process.env.EDITOR_DOMAIN
  : (window.ENV_DATA || {}).cdnEditorDomain || process.env.CDN_EDITOR_DOMAIN;

async function tagInit() {
  const tag = <HTMLScriptElement>document.getElementById('guestline-tag');

  if (tag === null) {
    window.GLAppInsights.trackTrace({
      message: 'Tag Not Found',
      properties: {
        page: ((window || {}).location || {}).href || 'Page info not available'
      }
    });
    return console.warn(
      'The tag might not be implemented correctly. Please refer to the documentation for more information'
    );
  }

  const collectionId = tag.getAttribute('data-group-id')!;
  const lng = (tag.getAttribute('data-lng') || window.navigator.language || 'en') as Language;
  const hotelId = tag.getAttribute('data-hotel-id') || undefined;

  const isNewDomainRedirectEnabled = await getFeature('enablenewdomainredirect', false, { collectionId });
  const oldDomain = (window.ENV_DATA || {}).dbmUrl || process.env.DBM_URL;
  const newDomain = (window.ENV_DATA || {}).dbmNewUrl || process.env.DBM_NEW_URL;
  const dbmURL = isNewDomainRedirectEnabled ? `https://${newDomain}/${collectionId.toUpperCase()}`  : `https://${collectionId.toLowerCase()}.${oldDomain}`;

  const triggerEventEmitter = new EventEmitter();

  window.GLAppInsights?.trackTrace({
    message: 'Tag Initiated',
    properties: {
      page: ((window || {}).location || {}).href || 'Page info not available',
      collectionId
    }
  });

  try {
    const {
      integration,
      analytics, 
      dbm,
      branding
    }: {
      analytics: AnalyticsConfig;
      integration: IntegrationConfig;
      dbm: DBMConfig;
      branding: BrandingConfig;
    } = await request.get(
      `${CDN_EDITOR_DOMAIN}/api/collections/${collectionId}/configs?sections[]=integration&sections[]=analytics&sections[]=dbm&sections[]=branding`
    );

    window.GLAppInsights?.trackTrace({
      message: 'Tag config',
      properties: { integration, collectionId }
    });

    // For the Editor to show the widget and refresh it
    if (window.guestlineEmitter) {
      import('./features/widget').then(({ default: renderWidget }) => {
        window.guestlineEmitter.on(
          'widget-render',
          (editorIntegrationConfig: IntegrationConfig, collectionId: string) => {
            renderWidget(editorIntegrationConfig, dbm, collectionId, hotelId);
          }
        );

        window.guestlineEmitter.emit('widget-ready');
      });
    } else {
      triggers({ integration, analytics, lng }, dbmURL, triggerEventEmitter);

      if (integration.panelEnabled) {
        const { default: renderPanel } = await import('./features/panel');

        renderPanel(dbmURL, triggerEventEmitter, collectionId, { branding, analytics, integration, lng });
      } else if (integration.widgetEnabled) {
        const { default: renderWidget } = await import('./features/widget');

        renderWidget(integration, dbm, collectionId, hotelId);
      }

      if (integration.basketEnabled) {
        const { default: basketTransfer } = await import('./features/basketTransfer');

        basketTransfer(dbmURL, analytics, integration, triggerEventEmitter);
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    window.GLAppInsights?.trackException({ exception: err as Error });
  }
}

export default async function main() {
  import('./monitoring')
    .then(async ({ default: monitoringInit }) => {
      monitoringInit();
      tagInit();
    })
    .catch(() => {
      tagInit();
    });
}
