import getGoogleAnalyticsLinkerQuery from './utils/googleAnalytics';
import { EventEmitter } from 'events';
import { AnalyticsConfig, IntegrationConfig } from './interfaces';
import qs from 'qs';

export interface RedirectConfig {
  integration: IntegrationConfig;
  analytics: AnalyticsConfig;
  lng?: string;
}

interface RedirectArgs {
  config: RedirectConfig;
  triggerEventEmitter?: any;
  params: any;
  dbmURL: string;
}

/**
 * Takes an object and returns an object without keys that have falsy values.
 * @param {Object} obj object to remove the falsy key/values from.
 * @returns {Object} the object without the falsy values.
 */
const filterFalsy = (obj: any) =>
  Object.keys(obj)
    .filter(x => !!obj[x])
    .reduce((acc, x) => ({ ...acc, [x]: obj[x] }), {});

export async function redirect({
  config: { integration, analytics },
  triggerEventEmitter,
  params = {},
  dbmURL
}: RedirectArgs) {
  let redirectUrl = dbmURL;

  let googleAnalyticsLinkerQuery = null;
  if (analytics.gtmContainerId) {
    googleAnalyticsLinkerQuery = await getGoogleAnalyticsLinkerQuery();
  }

  const { rooms, rates, ...searchParams } = params;

  if (rooms) searchParams.filters = { rooms };
  if (rates) searchParams.filters = { ...searchParams.filters, rates };

  if (searchParams.hotel && searchParams.hotel.indexOf(';') === -1) {
    redirectUrl += '/availability';
  }

  redirectUrl += qs.stringify(searchParams, {
    addQueryPrefix: true,
    encode: false
  });

  if (googleAnalyticsLinkerQuery) {
    redirectUrl += redirectUrl.includes('?') ? '&' : '?';
    redirectUrl += googleAnalyticsLinkerQuery;
  }

  if (triggerEventEmitter && integration && integration.panelEnabled) {
    triggerEventEmitter.emit('panel', { show: true, url: redirectUrl });
  } else if (integration && integration.newTabRedirect) {
    window.open(redirectUrl, '_blank');
  } else {
    window.location.assign(redirectUrl);
  }
}

export function getParentByDataAttribute(
  element: Element,
  attribute: string,
  value?: string
): Element | null {
  let elementWithAttribute = null;

  if (element && element.hasAttribute) {
    if (element.hasAttribute(attribute)) {
      if (value === undefined) {
        elementWithAttribute = element;
      } else if (element.getAttribute(attribute) === value) {
        elementWithAttribute = element;
      }
    }

    if (!elementWithAttribute) {
      elementWithAttribute = getParentByDataAttribute(
        element.parentNode as Element,
        attribute,
        value
      );
    }
  }

  return elementWithAttribute;
}

export default function(config: RedirectConfig, dbmURL: string, triggerEventEmitter: EventEmitter) {
  window.addEventListener(
    'click',
    event => {
      const redirectElement = getParentByDataAttribute(
        event.target as Element,
        'data-guestline-redirect'
      );

      triggerEventEmitter.emit('clicked', event);

      if (redirectElement) {
        // So we stop OBM from happening
        event.stopPropagation();
        event.preventDefault();

        const params = filterFalsy({
          hotel: redirectElement.getAttribute('data-hotel-id'),
          arrival: redirectElement.getAttribute('data-arrival'),
          departure: redirectElement.getAttribute('data-departure'),
          nights: redirectElement.getAttribute('data-nights'),
          adults: redirectElement.getAttribute('data-adults'),
          children: redirectElement.getAttribute('data-children'),
          promoCode: redirectElement.getAttribute('data-promo-code'),
          rates: redirectElement.getAttribute('data-rates'),
          rooms: redirectElement.getAttribute('data-rooms'),
          lng: redirectElement.getAttribute('data-lng') || config.lng
        });

        redirect({
          config,
          triggerEventEmitter,
          dbmURL,
          params
        });
      }
    },
    true
  );

  window.addEventListener(
    'submit',
    (event: Event) => {
      if ((event.target as HTMLFormElement).hasAttribute('data-guestline-form')) {
        // So we stop OBM from happening
        event.stopPropagation();
        event.preventDefault();

        const formElements: any = (event.target as HTMLFormElement).elements;
        const params = filterFalsy({
          hotel: (formElements['hotel-id'] || {}).value,
          arrival: (formElements['arrival'] || {}).value,
          departure: (formElements['departure'] || {}).value,
          nights: (formElements['nights'] || {}).value,
          adults: (formElements['adults'] || {}).value,
          children: (formElements['children'] || {}).value,
          promoCode: (formElements['promo-code'] || {}).value,
          rates: (formElements['rates'] || {}).value,
          rooms: (formElements['rooms'] || {}).value,
          lng: (formElements['lng'] || {}).value || config.lng
        });

        redirect({ config, triggerEventEmitter, dbmURL, params });
      }
    },
    true
  );
}
