import { __spreadArrays } from "tslib";
import { NullEventEmitter } from "./EventEmitter";
var disconnectedEventEmitter = new NullEventEmitter();
var Hooks = /** @class */ (function () {
    function Hooks(eventEmitter) {
        /** @inheritdoc */
        this.addListener = this.on;
        /** @inheritdoc */
        this.off = this.removeListener;
        this.eventEmitter = eventEmitter;
    }
    Hooks.prototype.tryDisconnect = function () {
        // Replacing the current IEventEmitter object (eventEmitter) with a special instance of IEventEmitter (disconnectedEventEmitter) achieves multiple things:
        // 1. determines whether the hooks instance has already been disconnected or not,
        // 2. removes implicit references to subscriber objects (so this instance won't keep them alive under any circumstances),
        // 3. makes sure that future subscriptions are ignored from this point on.
        var originalEventEmitter = this.eventEmitter;
        this.eventEmitter = disconnectedEventEmitter;
        return originalEventEmitter !== disconnectedEventEmitter;
    };
    /** @inheritdoc */
    Hooks.prototype.on = function (eventName, listener) {
        this.eventEmitter.on(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    Hooks.prototype.once = function (eventName, listener) {
        this.eventEmitter.once(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    Hooks.prototype.removeListener = function (eventName, listener) {
        this.eventEmitter.removeListener(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    Hooks.prototype.removeAllListeners = function (eventName) {
        this.eventEmitter.removeAllListeners(eventName);
        return this;
    };
    /** @inheritdoc */
    Hooks.prototype.listeners = function (eventName) {
        return this.eventEmitter.listeners(eventName);
    };
    /** @inheritdoc */
    Hooks.prototype.listenerCount = function (eventName) {
        return this.eventEmitter.listenerCount(eventName);
    };
    /** @inheritdoc */
    Hooks.prototype.eventNames = function () {
        return this.eventEmitter.eventNames();
    };
    /** @inheritdoc */
    Hooks.prototype.emit = function (eventName) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = this.eventEmitter).emit.apply(_a, __spreadArrays([eventName], args));
    };
    return Hooks;
}());
export { Hooks };
