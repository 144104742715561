import { __awaiter, __generator } from "tslib";
import { ProjectConfig } from "./ProjectConfig";
import { isPromiseLike } from "./Utils";
var InMemoryConfigCache = /** @class */ (function () {
    function InMemoryConfigCache() {
        this.cachedConfig = ProjectConfig.empty;
    }
    InMemoryConfigCache.prototype.set = function (_key, config) {
        this.cachedConfig = config;
    };
    InMemoryConfigCache.prototype.get = function (_key) {
        return this.cachedConfig;
    };
    InMemoryConfigCache.prototype.getInMemory = function () {
        return this.cachedConfig;
    };
    return InMemoryConfigCache;
}());
export { InMemoryConfigCache };
var ExternalConfigCache = /** @class */ (function () {
    function ExternalConfigCache(cache, logger) {
        this.cache = cache;
        this.logger = logger;
        this.cachedConfig = ProjectConfig.empty;
    }
    ExternalConfigCache.prototype.set = function (key, config) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!config.isEmpty) {
                            this.cachedSerializedConfig = ProjectConfig.serialize(config);
                            this.cachedConfig = config;
                        }
                        else {
                            // We may have empty entries with timestamp > 0 (see the flooding prevention logic in ConfigServiceBase.fetchLogicAsync).
                            // In such cases we want to preserve the timestamp locally but don't want to store those entries into the external cache.
                            this.cachedSerializedConfig = void 0;
                            this.cachedConfig = config;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.cache.set(key, this.cachedSerializedConfig)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.logger.configServiceCacheWriteError(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ExternalConfigCache.prototype.updateCachedConfig = function (externalSerializedConfig) {
        if (externalSerializedConfig == null || externalSerializedConfig === this.cachedSerializedConfig) {
            return;
        }
        this.cachedConfig = ProjectConfig.deserialize(externalSerializedConfig);
        this.cachedSerializedConfig = externalSerializedConfig;
    };
    ExternalConfigCache.prototype.get = function (key) {
        var _this = this;
        try {
            var cacheGetResult = this.cache.get(key);
            // Take the async path only when the IConfigCatCache.get operation is asynchronous.
            if (isPromiseLike(cacheGetResult)) {
                return (function (cacheGetPromise) { return __awaiter(_this, void 0, void 0, function () {
                    var _a, err_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this.updateCachedConfig;
                                return [4 /*yield*/, cacheGetPromise];
                            case 1:
                                _a.apply(this, [_b.sent()]);
                                return [3 /*break*/, 3];
                            case 2:
                                err_2 = _b.sent();
                                this.logger.configServiceCacheReadError(err_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/, this.cachedConfig];
                        }
                    });
                }); })(cacheGetResult);
            }
            // Otherwise, keep the code flow synchronous so the config services can sync up
            // with the cache in their ctors synchronously (see ConfigServiceBase.syncUpWithCache).
            this.updateCachedConfig(cacheGetResult);
        }
        catch (err) {
            this.logger.configServiceCacheReadError(err);
        }
        return Promise.resolve(this.cachedConfig);
    };
    ExternalConfigCache.prototype.getInMemory = function () {
        return this.cachedConfig;
    };
    return ExternalConfigCache;
}());
export { ExternalConfigCache };
